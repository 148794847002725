body {
    margin: 0px;
}

.MuiInputBase-root {
    background-color: #FAFAFA;
    // border: 1px solid #D9D9D9;
    border-radius: 6px
}

.MuiOutlinedInput-multiline {
    padding: 0 !important;
}

.rounded {
    .MuiOutlinedInput-root {
        border-radius: 20px;
    }
}

.MuiButton-containedPrimary:hover {
    background-color: #005B90 !important;
}

.MuiSvgIcon-root.svg-primary {
    path {
        stroke: #1EAAC4;
    }
}

.action-icons {
    .MuiSvgIcon-root {
        font-size: x-large !important;
    }
}

.react-tel-input {
    margin-top: 6px;
    // margin-bottom: 20px;
    display: flex;

    .form-control {
        background-color: #FAFAFA;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        width: 100%;
        margin-left: 48px;
        padding: 10px 14px;
    }

    .form-control:hover,
    .form-control:focus {
        border-color: #1EAAC4;
    }

    .error {
        border-color: red;
    }

    .flag-dropdown {
        border-radius: 3px;
    }

    .selected-flag:hover {
        background-color: transparent;
    }
}

.MuiBackdrop-root {
    z-index: 1 !important;
}

.MuiDialog-root {
    .MuiBackdrop-root {
        z-index: -1 !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.feedback-container,
.dispatcher-notification-container {
    table tr {
        border: none;
    }

    table {

        th,
        td {
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: unset;
                max-width: 100%;
            }
        }
    }
}

.action-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.error-address .css-13cymwt-control {
    border-color: red !important;
}

.error-address .css-13cymwt-control:hover {
    border-color: red !important;
}

.css-13cymwt-control {
    background-color: #FAFAFA !important;
}

.css-13cymwt-control:hover {
    border-color: #3498db !important;
}

.css-t3ipsp-control {
    background-color: #FAFAFA !important;
    box-shadow: none !important;
    border-color: #3498db !important;
}

// .MuiPaper-root {
//     min-width: 350px;
// }

.MuiButtonBase-root {
    text-transform: capitalize !important
}

.external-html {
    font-family: "Roboto, Helvetica, Arial, sans-serif",
}

.clickable-text {
    cursor: pointer;
    font-size: inherit !important;
    color: #3498db;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    z-index: -2
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border: 2px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
}

.fw-bold {
    font-weight: bold !important;
}

.d-inline {
    display: inline;
}

.success-container {
    background: rgba(29, 185, 84, 0.1);
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 10px;
    align-items: center;
}

.icon-button-without-padding {
    padding: 0 !important;
}

.rbc-btn-group {
    button {
        background-color: white;
        margin-bottom: 10px;
        border: #3691c5 1px solid;
        padding: 10px;
        width: 120px;
        height: 40px;
        justify-content: center;
        font-size: smaller;

    }



}

.rbc-toolbar {
    .rbc-btn-group:first-child {
        button {
            color: #005B90;
            width: 90px;

        }


        button:active,
        button:focus,
        button:hover {
            box-shadow: none;
            background-color: #D1FCF5;
            border-color: #D1FCF5;
        }

        button:first-child {
            margin-right: 20px;
            border-radius: 1ch;
        }

        button:not(:first-child):not(:last-child) {
            border-radius: 1ch;
            margin-right: 5px;
        }

        button:last-child {
            border-radius: 1ch;
        }

    }

    .rbc-btn-group:last-child {
        button {
            width: 90px;
        }

        button.rbc-active {
            background-color: #1EAAC4;
            color: #FAFAFA;

        }
    }

}

.rbc-toolbar-label {
    font-size: 17px;
    font-weight: bold;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.rbc-time-column {
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}


.rbc-row {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.rbc-row {
    .rbc-month-header {
        font-family: Roboto, Helvetica, Arial, sans-serif;
    }
}

.rbc-day-slot {

    .rbc-events-container {
        margin-right: auto;
    }
}

.notification-item {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    border: 1px solid #F3F4F6;
    padding: 16px;
    background-color: #fff;
    margin: 10px auto;
    width: 60%;
}

.seen {
    background-color: #F3F4F6;
}

.rbc-row-segment {
    .rbc-event-content {
        white-space: normal;
    }

}

.pn-msg-input__textarea{
    background-color: white;
}
.w-400{
    width: 400px;
}